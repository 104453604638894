/* eslint-disable quotes */
export default {
  label: {
    ok: 'Ok',
    confirm: 'Confirm',
    cancel: 'Cancel',
    download: 'Download',
    search: 'Search',
    recharge: 'Recharge',
    withdraw: 'Withdraw',
    withdrawal: 'Withdrawal',
    transfer: 'Transfer',
    balance: 'Balance',
    walletType: 'Wallet Type',
    id: 'ID',
    payment: 'Payment',
    addBank: 'Add Bank Card',
    updateBank: 'Edit Bank Card',
    email: 'Email',
    finish: 'Finish',
    save: 'Save',
    history: 'History',
    time: 'Time',
    order: 'Order',
    del: 'Delete',
    copy: 'Copy',
    won: 'Won',
    'agent-rule': 'Agent Rule',
    logIn: 'Log In',
    tip: 'Tip',
    lottery: 'Lottery',
    deposit: 'Deposit',
  },
  tip: {
    success: 'Successfully',
    copysuccess: 'Copy Successfully',
  },
  defaultPage: {
    noMore: 'No more',
  },
  alert: {
    logout: 'LOG OUT',
    sureLogout: 'Are you sure you want to log out?',
  },
  warning: {
    unopen: 'Not yet enabled',
  },
  notification: {
    title: 'Notification',
    announcements: 'Announcements',
    systemMessage: 'System Messages',
  },
  vip: {
    currentLevel: 'Current Level',
    collected: 'To be Collected',
    finished: 'To be Finished',
    received: 'Received',
    recharge: 'Recharge',
    move: 'more to reach level',
    title: 'VIP',
    table: {
      level: 'VIP level',
      reward: 'Reward',
      bouns: 'Bouns',
      spin: 'Spin',
    },
    success: 'Receive Successfully',
  },
  filter: {
    today: 'Today',
    yesterday: 'Yesterday',
    days: '{{day}} Days',
    time: 'Select a time',
    name: 'Filter',
  },
  luckyspin: {
    add: 'Add',
    spin: 'SPIN',
    won: 'Won',
    winner: 'Big Winners',
    mySpin: 'My Spin',
    myfree: 'My Free',
    morefree: 'More Free',
    free: 'Free',
    usefreefirst: 'Use Free First',
  },
  invitation: {
    rule: {
      title: 'Invitation Rules',
      paragraph: {
        invite:
          'Invite friends and recharge to get additional platform rewards!',
        after:
          'After being claimed, the rewards will be directly distributed to the wallet balance within 10 minutes.',
      },
      table: {
        invite: 'Invite Account',
        deposit: 'Deposit Account',
        bonus: 'Bonus',
        people: 'People',
      },
      detail: {
        title: 'Rules',
        paragraph1:
          'Only when the number of invited accounts is reached and each account can meet the recharge amount can you receive the bonus.',
        paragraph2:
          'The invitation account meets the requirements, but the recharge amount of the account does not meet the requirements, and the bonus cannot be claimed.',
        paragraph3:
          'Please claim the event bonus within the event period. All bonuses will be cleared after the event expires.',
        paragraph4:
          'Please complete the task within the event period.After the event expires, the invitation record will be cleared.',
      },
    },
    home: {
      title: 'Invite Friends',
      'code-title': 'My invitation code',
      income: 'Cumulative \nincome',
      count: 'Total Invited \nCount',
      record: 'Invitation \nrecord',
      'reward-rules': 'Invitation \nreward rules',
      bouns: 'Bouns',
      join: 'Join Agency',
      'join-tip': 'Be an agent and earn more commission.',
      invites: 'Number of invitees',
      recharges: 'Recharge per people',
      deposit: 'Deposit number',
      'go-complete': 'Go Complete',
      completed: 'Completed',
      rules: 'Rules',
      'copy-text':
        'Link Copied, Go To WhatsApp Or Telegram To Invite Friends Now.',
    },
  },
  proxy: {
    title: 'Agency Center',
    tier: 'Tier{{number}}',
    all: 'All',
    search: 'Search Phone number',
    join: 'Join Agency',
    rules: {
      header: 'Invitation Rules',
      title: 'Promotion partner',
      subtitle: 'ProgramThis activity is valid for a long time',
      paragraph1:
        'There are 4 referral levels when inviting friends. If A invites B, B becomes a level 1 referral of A. If B invites C, C becomes a level 1 referral of B and simultaneously a level 2 referral of A. If C invites D, D becomes a level 1 referral of C, a level 2 referral of B, and a level 3 referral of A.',
      paragraph2:
        'When inviting friends to register, you must send theinvitation link provided or enter the invitation codemanually so that your friends become your level 1subordinates.',
      paragraph3:
        "The invitee registers via the inviter's invitation code andcompletes the deposit, shortly after that thecommission will be received immediately",
      paragraph4:
        'The commission percentage depends on the Agent level. There are four level systems for Agents. Invite more people and update your agent level faster to earn more and more commission',
      paragraph5:
        "The percentage of the recharge bonus received from sub-agents varies depending on the agent's own level",
      'paragraph6-1':
        'The commission percentage depends on themembership level. The higher the membership level, thehigher the bonus percentage. Different game types alsohave different payout percentages',
      'paragraph6-2':
        'The commission rate is specifically explained as follows',
      paragraph7:
        'TOP20 commission rankings will be randomly awardedwith a separate bonus',
      tier: 'tier{{number}}',
      'agent-level': 'Agent Level',
      'commission-level': 'Commission Level',
      'team-member': 'Team\nMember',
      'team-recharge': 'Team\nRecharge',
      'team-betting': 'Team\nBetting',
      lottery: 'Lottery commission percentage',
      scratch: 'Scratch card Slots commission percentage',
      casino: 'Casino commission percentage',
      sports: 'Sports commission percentage',
    },
    filter: {
      recharge: 'Recharge',
      commission: 'Commission',
    },
    user: {
      id: 'ID:',
      'register-date': 'Register Date:',
      'total-user': 'Total Users:',
      'total-commission': 'Total Commission:',
      'new-user': 'New User',
      'new-user-title': `Its Team's Data`,
      'recharge-user': 'Recharge Users',
      users: 'Users',
      active: 'Active',
      total: 'Total Data',
      today: "Today's User Data",
      commission: 'Commission',
      recharge: 'Recharge',
      betting: 'Betting',
      wonlost: 'Won\\Lost',
      'error-no-performance':
        'This user currently has no performance. \nIs there an issue?',
      contact: 'Contact now',
      'error-no-play':
        "This user hasn't played any games in the last 7 days.\n Is there an issue? ",
      'up-line': 'Up-Line',
    },
    'commission-detail': {
      title: 'Commission Detail',
      total: 'Total Commission',
      sort: 'Commission Sort',
      user: {
        title: 'User Details',
        amount: 'Recharge amount',
        rebate: 'Rebate',
        commission: 'Recharge commission',
        game: 'Game',
        result: 'Result',
      },
    },
    'commission-base': {
      commissionRate: 'Commission Rate',
      commissionType: 'Type',
      commissionTier1: 'Tier 1',
      commissionTier2: 'Tier 2',
      commissionTier3: 'Tier 3',
      commissionTier4: 'Tier 4',
      commissionLv: 'Invite More Friends To Upgrade To Lv',
      commissionLvE: ' Earn Higher Commissions.',
      commissionLv4: 'Earn Higher Commissions',
    },
    'team-report': {
      'new-user': 'New User',
      recharge: 'Recharge',
      betting: 'Betting',
      'recharge-commission': 'Recharge Commission',
      'betting-commission': 'Betting commission',
      'my-teams': 'My Teams',
      'user-id': 'User ID',
      user: 'User',
      commission: 'Commission',
      'won-loss': 'Won/Loss',
      won: 'Won',
      loss: 'Loss',
      'user-view': 'User View',
      'up-line': 'Up-line',
      'register-date': 'Register Date:',
      'games-played7': 'Games played in the past 7 days',
      'game-name': 'Game Name',
      tier: 'Tier',
      'no-search': 'No results found in the search',
      'no-data': 'No Data',
      next: 'Next',
    },
    team: {
      title: 'Team Report',
      grade: 'Grade',
      date: 'Date:',
      time: 'Select Time',
      total: 'Total Data',
      today: 'Today',
      yesterday: 'Yesterday',
      threedays: '3days',
      sevendays: '7days',
      fifteendays: '15days',
      thirtydays: '30days',
      deposit: 'Deposit number',
      betting: 'Betting commission',
      recharge: 'Recharge commission',
      search: 'Enter subordinates phone number',
      contact: 'Contact now',
    },
    home: {
      'agent-id': 'Agent ID:',
      rebate: 'Rebate',
      'my-invitation-code': 'My invitation code',
      'reset-link': 'Reset Link',
      'invitation-link': 'INVITATION LINK',
      'team-report': 'Team report',
      'commission-detail': 'Commission detail',
      'new-subordinates': 'New subordinates',
      'invitation-rules': 'Invitation rules',
      'agent-line-customer-service': 'Agent line customer service',
      'commission-ranking': 'Commission Ranking',
      'agents-whose-commissions-exceed': 'Agents whose commissions exceed ',
      me: 'Me',
    },
    'new-user': {
      title: 'New User',
      today: 'New Users Today:',
      error:
        'No new users yet! \nInvite your friends to earn more commissions.',
      invite: 'Invite now',
    },
    'commission-card': {
      commission: 'Commission Amount',
    },
  },
  'invitation-record': {
    'invitation-record': 'Invitation record',
    status: 'Status',
    'registration-time': 'Registration Time',
    invite: 'Invite',
    'bonus-detail': 'Bonus {{bonus}} Details',
    noInvitedFriend: 'No matching invitation record',
  },
  referral: {
    label: {
      title: 'SIGN UP AND GET GIFTS',
    },
    tip: {
      desc: 'If you do not have an account, it will be automatically registered for you',
    },
  },
  'error-pages': {
    refresh: {
      label: {
        title: 'Something went terribly wrong !',
        'sub-title': 'Please refresh your page',
        'try-again': 'Try Again',
      },
    },
    'no-data': {
      label: {
        title: 'No Data',
      },
    },
    'not-found': {
      label: {
        title: 'The current network signal is poor',
        'sub-title': 'please check your network',
      },
    },
    'no-result': {
      label: {
        title: 'No search result !',
      },
    },
  },
  share: {
    'copy-success': 'Copy Successfully',
  },
  login: {
    label: {
      login: 'LOGIN',
      skip: 'Skip',
      sign: 'Sign Up',
      next: 'NEXT',
      'select-region': 'Select Region',
      'otp-login': 'OTP Login',
      'password-login': 'Password Login',
      'get-otp': 'GET OTP',
      'set-password': 'Set Password',
    },
    tip: {
      'no-phone': 'Please enter Phone Number',
      'no-password': 'Please enter Password',
      'no-otp': 'Please enter OTP',
      phone: 'Enter Phone Number',
      password: 'Enter Password(6-18)',
      passwordRule:
        'The length must be 8~16 and contain both numbers and letters',
      'confirm-password': 'Confirm Password',
      forgot: 'Forgot your password?',
      'new-account': 'Need an account?',
      'sing-in': 'SIGN UP',
      otp: 'Enter OTP',
      'referral-code': 'Referral Code',
      confrim18: 'I confirm I am 18+',
      'allow-notify':
        'Allow us to notify you important winning information through this phone number.',
      'has-account': 'Already a user?',
      'has-account-07': 'Already have an account?',
      'sign-in': 'LOGIN',
      page18: 'I confirm I am 18+',
      notify:
        'Allow us to notify you important winning informationthrough this mobile number .',
      'pwd-dif': 'The tow passwords is inconsistent',
      modified:
        'The password has been successfully modified. Please log in again',
      'game-not-active':
        'Sorry, the game is under maintenance. Please try again later.',
    },
  },
  paidSuccess: {
    label: {
      title: 'Payment',
      subTitle: 'Paid successfully',
      viewOrder: 'View Order',
      backHome: 'Back Home',
      content:
        'Bet placed successfully. Click to Chatroom Share and you can get a reward!',
    },
    tip: {
      msg: 'Your tickets have been successfully purchased. Please take note of the draw time and check the results promptly.',
    },
  },
  'recharge-page': {
    currentMethod: 'Current Method',
    label: {
      records: 'records',
    },
    tip: {
      payMethodTip:
        'Please switch to another method if the current method failed.',
      'money-error': 'Please enter the correct amount',
      'paymethod-error': 'Please select pay method',
    },
  },
  'bets-page': {
    label: {
      share: 'Share',
      result: 'Result',
      bettingTime: 'Betting time',
      shareAwards: 'Share for Reward',
      drawTime: 'Draw time',
      playNow: 'Play Now',
      viewDetails: 'View Details',
    },
    status: {
      won: 'WON',
      noWin: 'Lost',
      drawn: 'DRAWN',
      toBe: 'TO BE DRAWN...',
      settling: 'TICKET SETTLING...',
      settled: 'TICKET SETTLED',
    },
  },
  'bets-detail': {
    label: {
      orderDetail: 'Order Details',
      number: 'Number',
      time: 'Time',
      payment: 'Payment',
      result: 'Result',
      gameType: 'Game type',
      won: 'Won',
      noWin: 'No win',
      noDraw: 'To Be Drawn...',
      settling: 'Ticket settling...',
      draw: 'Draw',
      totalPoints: 'TOTAL POINT',
      delivery: 'Delivery',
      fee: 'Fee',
      drawRes: 'Drawn results',
      issue: 'Issue',
      sum: 'Sum',
      value: 'Value',
      name: 'Name',
      type: 'Type',
    },
  },
  'withdraw-page': {
    label: {
      addBank: 'Add Bank Account',
      transferTo: 'Transfer to bank account',
      withdrawAmount: 'Withdrawable Amount',
      withdrawAmountLower: 'Withdrawal amount',
      received: 'Actual amount received:',
      chooseCard: 'Choose a bank card',
      success: 'Withdrawal successful',
      enterText:
        'Please enter in round figure in <mark>50s</mark> and <mark>100s</mark>',
    },
    tip: {
      withdrawSubmitted:
        'Your withdrawal has been submitted please be patient.',
      transfer: 'Transferring to the platform wallet can earn extra rewards.',
    },
    placeholder: {
      enterAmount: 'Enter Withdrawable amount',
    },
    rules: {
      fee: 'Withdrawal will be charged with <mark>3%</mark> of withdraw fee will be charged.',
      times: 'Each user could withdraw <mark>(3)</mark> times per day.',
      note: '*Note: withdraw may be delayed due to someBank issues. In this case, the withdrawn amount will be returned to your wallet. Thank you foryour patience.',
      limit:
        'We have found some money laundry behaviorsSo limits that recharge amount should be usedfor betting only. The commission and winninoamount could be withdrawn immediately.',
    },
    error: {
      addCard: 'Please add a bank card first',
      addAmount: 'Please enter the amount',
    },
  },
  'bank-page': {
    label: {
      accountName: 'Account name',
      IFSC: 'IFSC Code',
      accountNum: 'Account Number',
      accountNumAgain: 'Account Number Again',
      UPI: 'Enter UPI Address',
      phoneNumber: 'phone number',
    },
    placeholder: {
      accountName: 'English letters(A-Z.a-z) and space',
      IFSC: 'Please enter the IFSC Code',
      accountNum: '9-17 number, no space',
      UPI: 'Please enter your UPI ID',
      email: 'Please enter the Email',
    },
    del: {
      confirmTitle: 'Delete bank card',
      confirmText: 'Are you sure you want to delete this bank card?',
    },
    error: {
      'check-ifsc': 'Please check your IFSC code',
    },
  },
  'transfer-page': {
    label: {
      withdrawable: 'Withdrawable',
      balance: 'Transferable Balance',
      balanceTitle: 'Transfer to Recharge Wallet',
      willGet: 'Will get',
      total: 'Total Wallet',
    },
    tip: {
      tip: 'After clicking Confirm,your withdrawable balance will be converted into Recharge wallet and you will get the corresponding bonus.',
      success: 'Conversion successful',
    },
  },
  records: {
    label: {
      amount: 'Amount',
      channel: 'Channel',
      received: 'Received Amount',
      commission: 'Commission',
      withdrawChannel: 'Withdraw Channel',
      orderNum: 'Order Number',
      reward: 'Reward',
      order: 'Order',
      game: 'Game',
      types: 'Types',
      receiptTime: 'Time of receipt',
    },
    status: {
      PROCESS: 'In progress...',
      COMPLETE: 'COMPLETE',
      REVIEW: 'In review...',
      FAILED: 'failed',
    },
    tip: {
      error: 'Account error',
      renewTime: 'within 24 hours',
    },
  },
  'bets-share': {
    label: {
      share: 'Share',
      shareSuccess: 'Share successful',
      shareFail: 'Share failed',
      receiving: 'Congratulations on receiving',
      reward: 'reward',
      check: 'Please Check Your Network connection',
      connection: 'Connection',
      friendlyReminder: 'Friendly reminder',
      frTip: 'Please Read And Agree To The',
      frContent: '(Pre-sale rules)',
    },
  },
  datePicker: {
    label: {
      select: 'Select Date',
    },
  },
  promotion: {
    title: 'Promotions',
  },
  'game-page': {
    label: {
      more: 'Show more',
      green: 'green',
      violet: 'violet',
      red: 'red',
      bet: 'Bet',
      howToPlay: 'How to play',
      remaining: 'Time remaining',
      issue: 'ISSUE',
      number: 'NUMBER',
      color: 'COLOR',
      perriod: 'PERRIOD',
      guessWrong: 'Sorry,Your guessing is wrong,Try next time',
      ticketSetting: 'Settlement in progress, please wait...',
      guessRight: 'Congratulations,You have won!',
      betTime: 'Betting time',
      myBets: 'My Bets',
      total: 'Total Prize Amount',
      drawResult: 'Draw results',
      betResult: 'Bet Result',
      wager: 'Wager',
      payout: 'Payout',
      Odds: 'Odds',
      odd: 'Odd',
      quickGuess: 'Quick Guess',
      small: 'small',
      time: 'Time',
      unused: 'Unused',
      big: 'big',
      numbers: 'numbers',
      playNow: 'Play Now',
      add: 'add',
      box: 'box',
      win: 'Win',
      payment: 'Payment',
      single: 'Single',
      double: 'Double',
      triple: 'Triple',
      totalLabel: 'Total',
      digit: 'Digit',
      myNum: 'My Numbers',
      allEmpty: 'All empty',
      result: 'RESULT',
      totalPoint: 'TOTAL POINT',
      draw: 'DRAW',
      rule: 'Rule',
      sumType: 'Sum Type',
      sumPoints: 'Sum Of Points',
    },
    'change-bets': {
      p1: 'You have placed an order for the Text',
      p2: 'Are you sure you want to remove your previous selections?',
    },
    tab: {
      result: 'Result history',
      winners: 'Winners',
      analyze: 'Analyze',
      order: 'My order',
    },
    'sale-rules': {
      title: 'Sale Rules',
      p1: 'In order to protect the legitimate rights and interests of users participating in the pre-sale and maintain the normal operating order of the pre-sale, these rules are formulated in accordance with relevant agreements and laws and regulations. country Chapter 1 Definition.',
      p2: '1.1 Pre-sale definition: refers to a sales model in which a seller offers a bundle of a product or service, collects consumer orders through product tools before selling, and makes it available to customers. consumers of goods and/or services by prior agreement',
      p3: '1.2 Presale mode is "deposit" mode. "Consignment" refers to the pre-delivery of a fixed number of items prior to sale. "Deposit" Scam Join mini games for a chance to win more deposits. Deposits can be exchanged directly for goods. Deposit is not refundable.',
      p4: '1.3 Pre-sale product: A product that is shipped by the seller using the pre-sale product tool. Only highlight the word presale on the product name or product detail page, and products that do not use the presale product tool are not presale.',
      p5: '1.4 Pre-sale system: refers to the system product tool that helps sellers to sell samples before selling.',
      p6: '1.5 Product price before selling: is the selling price of the product before selling. The price of pre-sale items consists of two parts: deposit and final payment.',
    },
    tip: {
      paySuccess:
        'Your tickets have been successfully purchased. Please take note of the draw time and check the results promptly.',
      unable: 'Unable to purchase in the last {{time}} seconds',
      single:
        'Choose 3 or more different numbers. If the draw results match your selection, you win.',
      double:
        'Choose 2 same numbers. lf the drawresults match your selection, you win(unless 3 numbers are the same).',
      triple:
        'Choose 3 same numbers. lf the drawresults match your selection, you win.',
      any: 'Choose any 3 same numbers. lf the drawresults are any three of the same numbers.you win.',
      'single-odd': '3 different numbers: odds(34.56)',
      'double-odd': '2 matching numbers: odds(13.83)',
      'triple-odd': '3 of the same number: odds(207.36)',
      'any-odd': 'Any 3 of the same number: odds(34.56)',
      'tip-clear': 'Please confirm you want to clear all numbers?',
    },
    notice: {
      available:
        'Tickets will not be available for purchase 3 minutes before the draw',
    },
  },
  'mix-lotto': {
    tip: {
      disableTip: 'Sorry, you cannot select numbers that are repeated.',
      numUneven: 'You must choose at least one number in each group',
      gameClosed:
        'The game has ended, and betting is not allowed. Please place your bets on other games.',
      numberModeTip:
        'Please select at least 1 number(s) for each digit. Max 100 bet slip count to be created each time.',
      '1digitModeTip': 'Please select at least 1 number for every digit.',
      numberRepeated: 'Sorry, you cannot select numbers that are repeated.',
      betMax: 'Sorry, the maximum number of bets you can place is {{num}}',
      minAmount: 'The minimum amount you can bet is {{num}}',
      beforePlay: 'Please click the add button before making a payment.',
      cantAddToCart:
        'The following combinations will not be added to the shopping cart',
    },
    label: {
      betSlipCount: 'Bet Slip Counts',
      addToBetSlip: 'Add to bet slip',
      gameClosed: 'Game Closed',
      Fish: 'Fish',
      FISH: 'FISH',
      Prawn: 'Prawn',
      PRAWN: 'PRAWN',
      Crab: 'Crab',
      CRAB: 'CRAB',
      Odd: 'Odd',
      Even: 'Even',
      Big: 'Big',
      est: 'Est. winning',
      Small: 'Small',
      BETTYPE: 'Bet Type',
      betAmount: 'Betting amount',
      name: 'India Lottery',
      official: 'Official Draw',
      rule: {
        exapmle: 'Exapmle',
        betOn: 'Player Bet On',
        towSide: {
          BSTitle: '1. Big/Small for Single Digit',
          OETitle: '2. Odd/Even for Single Digit',
        },
        singleTitle: 'Number for Single Digit',
        singleSubTitle: '{{value}} for Single Digit',
        FPCTitle: 'Number for Single Digit is {{value}}',
        FPCSubTitle: 'Number for Single Digit is {{value}}',
        mixTitle: 'Number for {{num}} Digit',
      },
    },
    desc: {
      rule: {
        desc1:
          'India Lottery is based on the result from India official lotteries. Players can bets on the last four digits from official results. For example, official result is 1st Prize : 123456, then the 1st Prize is 3, the 1st Prize-3rd digit is 4, the 1st Prize-2nd digit is 5, the 1st Prize-1st digit is 6.',
        desc2:
          'If there is no result provided by the official site within 24 hours, the bets for the respective draw will be cancelled.',
        desc3:
          'Any amendment made by the game’s official site to its results after 24 hours will not be accepted.',
        howToPlay1:
          'When you enter the game, you can see counters available on the screen.',
        howToPlay2:
          'Click on the selection of the counter which you would like to place your bet on. You will be presented with a bet slip where you can key your stake for the bet.',
        howToPlay3:
          'Next, you can select more selection to place bet or click on the "Place Bet" button to confirm all your bet. You may also click on the "Remove" icon to remove single or all bets.',
        howToPlay4:
          'Any bet placed that has not been confirmed will not be accepted by the system. You can refer to "Open Bets" page to see the bet details of your bet. Once the bet has been settled, then your bet can be seen in "Statement" page. In the event that a draw has been cancelled, all bets for the draw will be voided and cancelled.',
        howToPlay5:
          'Our India Lottery wager can be placed on the following bet types, each of the bet types is further explained below',
        betOn: '"{{betValue}}" for the 1st Prize-{{betType}}',
        drawResult: '1st Prize is {{result}}',
        betResult:
          'The 1st Prize-{{betType}} is "{{result}}", which is a "{{betValue}}" number, therefore the bet wins.',
        FPCTitle:
          'Bet placed on a "Fish"/"Prawn"/"Crab" number for a single digit.',
        mixD: 'Choose four numbers to match the results. They need to match exactly. That is, if the bet is {{num}}, the result must also be {{num}}.',
        mixX: 'Choose four numbers to match the results. If the result is a combination of these four numbers, you will win.',
        towSide: {
          BSTitle:
            'Bet placed on a "Big" or "Small" number for a single digit.',
          OETitle: 'Bet placed on a "Odd" or "Even" number for a single digit.',
        },
      },
    },
  },
  sports: {
    selectTip: 'Please select a sports betting platform',
    sports: 'Sports',
    play: 'Play now',
  },
  'result-page': {
    label: {
      latestDrawDate: 'Latest draw date',
    },
  },
};
